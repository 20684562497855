








































































































































@import "@/assets/scss/variables.scss";
  .modal-mask{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  h1 {
    font-size: 1.17em;
    letter-spacing: -0.04rem;
    line-height: inherit;
  }
  a.close {
    display: block;
  }
  form {
    padding: 1rem 2rem;

    .select{
      &:invalid {
        color: gray;
      }

      // Styling for browsers which do support
      // styling select option elements directly
      [disabled] {
        color: gray;
      }

      option {
        color: $dark;
      }
    }

    &>*{
      margin-bottom: 17px;
    }
    &.danger {
      input {
        outline: 1px solid $danger-color;
      }
    }
  }
